<template>
  <div>
    <div class="box box--white mt-32">
      <h3 class="headline--md text-bold">Reward Amount (in USD)</h3>

      <div v-if="referralReward" class="d-flex align-end mt-12">
        <div>
          <p class="text-bold">Current Referral reward amount: {{ referralReward.value }} USD</p>
          <p class="text-sm text-warm">Last update: {{ referralReward.updated_at | moment_lts }}</p>
        </div>

        <div v-if="$auth('Page', currentUser, $accessRule.ReferralSettingUpdate).canView()" class="d-flex align-end">
          <div class="form-group mr-8">
            <label class="form-label d-block">New reward amount</label>
            <input v-model="$v.referralParams.value.$model" type="text" class="form-control form-control--md" />
          </div>

          <button
            class="btn btn--md btn--primary"
            :disabled="!isReferralFormValid"
            @click="onOpenConfirmDialog(referralReward.id)"
          >
            Submit
          </button>
        </div>
      </div>

      <div class="divider mt-32 mb-32"></div>

      <div v-if="kycReward" class="d-flex align-end mt-12">
        <div>
          <p class="text-bold">Current KYC reward amount: {{ kycReward.value }} USD</p>
          <p class="text-sm text-warm">Last update: {{ kycReward.updated_at | moment_lts }}</p>
        </div>

        <div v-if="$auth('Page', currentUser, $accessRule.ReferralSettingUpdate).canView()" class="d-flex align-end">
          <div class="form-group mr-8">
            <label class="form-label d-block">New reward amount</label>
            <input v-model="$v.kycParams.value.$model" type="text" class="form-control form-control--md" />
          </div>

          <button
            class="btn btn--md btn--primary"
            :disabled="!isKycFormValid"
            @click="onOpenConfirmDialog(kycReward.id)"
          >
            Submit
          </button>
        </div>
      </div>
    </div>

    <confirm-dialog
      v-if="$auth('Page', currentUser, $accessRule.ReferralSettingUpdate).canView() && isOpenConfirmChangeReward"
      :open.sync="isOpenConfirmChangeReward"
      confirmText="Are you sure you would like to submit a new Reward amount?"
      confirmButtonText="Submit"
      confirmButtonClass="text-purple"
      @confirm="onUpdateReferralSetting"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import referralFormValidator from '@/validations/referral-form'
const ConfirmDialog = () => import('@/components/ConfirmDialog')

export default {
  data() {
    return {
      isOpenConfirmChangeReward: false,
      referralParams: null,
      kycParams: null,
      targetUpdate: 0
    }
  },

  mixins: [referralFormValidator],

  components: {
    ConfirmDialog
  },

  computed: {
    ...mapState('setting', ['referralReward', 'kycReward'])
  },

  methods: {
    ...mapActions('setting', ['getReferralSetting', 'updateReferralSetting']),

    initParams() {
      if (this.referralReward) {
        this.referralParams = {
          ...this.referralReward,
          value: ''
        }
      }

      if (this.kycReward) {
        this.kycParams = {
          ...this.kycReward,
          value: ''
        }
      }
    },

    onOpenConfirmDialog(id) {
      this.isOpenConfirmChangeReward = true
      this.targetUpdate = id
    },

    onUpdateReferralSetting() {
      const requestParams = this.referralParams.id === this.targetUpdate ? this.referralParams : this.kycParams

      this.updateReferralSetting(requestParams)
        .then(() => {
          this.initParams()
        })
        .finally(() => {
          this.isOpenConfirmChangeReward = false
        })
    }
  },

  async created() {
    await this.getReferralSetting()
    this.$v && this.$v.$touch()
    this.initParams()
  }
}
</script>
