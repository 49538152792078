import { required } from 'vuelidate/lib/validators'

export default {
  computed: {
    isReferralFormValid() {
      return !this.$v.referralParams.value.$error
    },

    isKycFormValid() {
      return !this.$v.kycParams.value.$error
    }
  },

  validations: {
    referralParams: {
      value: { required }
    },

    kycParams: {
      value: { required }
    }
  }
}
